var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    { staticClass: "prepayment-and-deposit-part" },
    [
      _c(
        "CCol",
        { attrs: { md: "8" } },
        [
          _c(
            "ValidatedFormCard",
            {
              ref: "validatedFormCard",
              attrs: {
                titleTranslationKey:
                  "components.playroomEditParts.PrepaymentAndDepositPart.title",
                titleTooltipTranslationKey:
                  "components.playroomEditParts.PrepaymentAndDepositPart.titleTooltip",
                ready: _vm.ready,
                model: _vm.$v,
                onSave: _vm.save
              },
              on: {
                "part-save-status-type-change": function(status) {
                  return _vm.$emit("part-save-status-type-change", status)
                }
              }
            },
            [
              _c(
                "CForm",
                [
                  _c("ValidatedInput", {
                    attrs: {
                      translationKey: "playroom.prepaymentForHourlyBooking",
                      "data-cy": "prepaymentForHourlyBooking-field",
                      field: _vm.$v.playroom.prepaymentForHourlyBooking,
                      value: _vm.$v.playroom.prepaymentForHourlyBooking.$model,
                      disabled: !_vm.playroom.allowHourBooking,
                      type: "number"
                    },
                    on: {
                      "update:value": function($event) {
                        return _vm.$set(
                          _vm.$v.playroom.prepaymentForHourlyBooking,
                          "$model",
                          $event
                        )
                      },
                      focusChange: _vm.onFocusChange
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-content",
                        fn: function() {
                          return [
                            _c("span", {
                              directives: [
                                {
                                  name: "t",
                                  rawName: "v-t",
                                  value:
                                    "components.playroomEditParts.PrepaymentAndDepositPart.percentOfBookingValue",
                                  expression:
                                    "'components.playroomEditParts.PrepaymentAndDepositPart.percentOfBookingValue'"
                                }
                              ]
                            })
                          ]
                        },
                        proxy: true
                      }
                    ])
                  }),
                  _c("ValidatedInput", {
                    attrs: {
                      translationKey: "playroom.prepaymentForOvernightBooking",
                      "data-cy": "prepaymentForOvernightBooking-field",
                      field: _vm.$v.playroom.prepaymentForOvernightBooking,
                      value:
                        _vm.$v.playroom.prepaymentForOvernightBooking.$model,
                      disabled: !_vm.playroom.allowOvernightStay,
                      type: "number"
                    },
                    on: {
                      "update:value": function($event) {
                        return _vm.$set(
                          _vm.$v.playroom.prepaymentForOvernightBooking,
                          "$model",
                          $event
                        )
                      },
                      focusChange: _vm.onFocusChange
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-content",
                        fn: function() {
                          return [
                            _c("span", {
                              directives: [
                                {
                                  name: "t",
                                  rawName: "v-t",
                                  value:
                                    "components.playroomEditParts.PrepaymentAndDepositPart.percentOfBookingValue",
                                  expression:
                                    "'components.playroomEditParts.PrepaymentAndDepositPart.percentOfBookingValue'"
                                }
                              ]
                            })
                          ]
                        },
                        proxy: true
                      }
                    ])
                  }),
                  _c("ValidatedSwitch", {
                    attrs: {
                      translationKey: "playroom.prepaymentOnlyForNewGuests",
                      "data-cy": "prepaymentOnlyForNewGuests-field",
                      field: _vm.$v.playroom.prepaymentOnlyForNewGuests,
                      checked: _vm.$v.playroom.prepaymentOnlyForNewGuests.$model
                    },
                    on: {
                      "update:checked": function($event) {
                        return _vm.$set(
                          _vm.$v.playroom.prepaymentOnlyForNewGuests,
                          "$model",
                          $event
                        )
                      }
                    }
                  }),
                  _c("hr"),
                  _c("ValidatedInput", {
                    attrs: {
                      translationKey: "playroom.deposit",
                      "data-cy": "deposit-field",
                      field: _vm.$v.playroom.deposit,
                      value: _vm.$v.playroom.deposit.$model,
                      type: "number"
                    },
                    on: {
                      "update:value": function($event) {
                        return _vm.$set(
                          _vm.$v.playroom.deposit,
                          "$model",
                          $event
                        )
                      },
                      focusChange: _vm.onFocusChange
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-content",
                        fn: function() {
                          return [
                            _c("span", {
                              directives: [
                                {
                                  name: "t",
                                  rawName: "v-t",
                                  value:
                                    "components.playroomEditParts.PrepaymentAndDepositPart.amountPerBooking",
                                  expression:
                                    "'components.playroomEditParts.PrepaymentAndDepositPart.amountPerBooking'"
                                }
                              ]
                            })
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCol",
        { attrs: { md: "4" } },
        [
          _c("HelpCard", {
            attrs: {
              focusFor: _vm.focusFor,
              name:
                "components.playroomEditParts.PrepaymentAndDepositPart.info.box1"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }