var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    { staticClass: "invoice-information-part" },
    [
      _c(
        "CCol",
        { attrs: { md: "8" } },
        [
          _c(
            "ValidatedFormCard",
            {
              ref: "validatedFormCard",
              attrs: {
                titleTranslationKey:
                  "components.playroomEditParts.InvoiceInformationPart.title",
                titleTooltipTranslationKey:
                  "components.playroomEditParts.InvoiceInformationPart.titleTooltip",
                ready: _vm.ready,
                model: _vm.$v,
                onSave: _vm.save
              },
              on: {
                "part-save-status-type-change": function(status) {
                  return _vm.$emit("part-save-status-type-change", status)
                }
              }
            },
            [
              _vm.ready
                ? _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        { attrs: { md: "12" } },
                        [
                          _c("ValidatedSelect", {
                            attrs: {
                              field: _vm.$v.playroom.rentType,
                              options: _vm.rentTypeOptions,
                              value: _vm.$v.playroom.rentType.$model,
                              translationKey: "playroom.rentType"
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(
                                  _vm.$v.playroom.rentType,
                                  "$model",
                                  $event
                                )
                              },
                              focusChange: _vm.onFocusChange,
                              change: _vm.resetCompanyLegalFields
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        { attrs: { md: "6" } },
                        [
                          _c("ValidatedInput", {
                            attrs: {
                              field: _vm.$v.playroom.invoiceFirstName,
                              value: _vm.$v.playroom.invoiceFirstName.$model,
                              translationKey: "playroom.invoiceFirstName"
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(
                                  _vm.$v.playroom.invoiceFirstName,
                                  "$model",
                                  $event
                                )
                              },
                              focusChange: _vm.onFocusChange
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        { attrs: { md: "6" } },
                        [
                          _c("ValidatedInput", {
                            attrs: {
                              field: _vm.$v.playroom.invoiceLastName,
                              value: _vm.$v.playroom.invoiceLastName.$model,
                              translationKey: "playroom.invoiceLastName"
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(
                                  _vm.$v.playroom.invoiceLastName,
                                  "$model",
                                  $event
                                )
                              },
                              focusChange: _vm.onFocusChange
                            }
                          })
                        ],
                        1
                      ),
                      !_vm.isCompanyDisabled
                        ? _c(
                            "CCol",
                            { attrs: { md: "6" } },
                            [
                              _c("ValidatedInput", {
                                attrs: {
                                  field: _vm.$v.playroom.invoiceCompanyName,
                                  value:
                                    _vm.$v.playroom.invoiceCompanyName.$model,
                                  translationKey: "playroom.invoiceCompanyName"
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.playroom.invoiceCompanyName,
                                      "$model",
                                      $event
                                    )
                                  },
                                  focusChange: _vm.onFocusChange
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.isCompanyDisabled
                        ? _c(
                            "CCol",
                            { attrs: { md: "6" } },
                            [
                              _c("ValidatedSelect", {
                                attrs: {
                                  field: _vm.$v.playroom.companyLegalType,
                                  options: _vm.companyLegalTypeOptions,
                                  value:
                                    _vm.$v.playroom.companyLegalType.$model,
                                  translationKey: "playroom.companyLegalType"
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.playroom.companyLegalType,
                                      "$model",
                                      $event
                                    )
                                  },
                                  focusChange: _vm.onFocusChange
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isCompanyLegalNameDisabled
                        ? _c(
                            "CCol",
                            { attrs: { md: "12" } },
                            [
                              _c("ValidatedInput", {
                                attrs: {
                                  field: _vm.$v.playroom.companyLegalName,
                                  value:
                                    _vm.$v.playroom.companyLegalName.$model,
                                  translationKey: "playroom.companyLegalName"
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.playroom.companyLegalName,
                                      "$model",
                                      $event
                                    )
                                  },
                                  focusChange: _vm.onFocusChange
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _c("AddressForm", {
                attrs: {
                  field: _vm.$v.playroom,
                  value: _vm.$v.playroom.$model
                },
                on: {
                  "update:value": function($event) {
                    return _vm.$set(_vm.$v.playroom, "$model", $event)
                  },
                  focusChange: _vm.onFocusChange
                }
              }),
              _vm.showVatIdAndTaxId
                ? _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        { attrs: { md: "6" } },
                        [
                          _c("ValidatedInput", {
                            attrs: {
                              field: _vm.$v.playroom.vatId,
                              value: _vm.$v.playroom.vatId.$model,
                              translationKey: "playroom.vatId"
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(
                                  _vm.$v.playroom.vatId,
                                  "$model",
                                  $event
                                )
                              },
                              focusChange: _vm.onFocusChange
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        { attrs: { md: "6" } },
                        [
                          _c("ValidatedInput", {
                            attrs: {
                              field: _vm.$v.playroom.taxId,
                              value: _vm.$v.playroom.taxId.$model,
                              translationKey: "playroom.taxId"
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(
                                  _vm.$v.playroom.taxId,
                                  "$model",
                                  $event
                                )
                              },
                              focusChange: _vm.onFocusChange
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("hr"),
              _c("div", { staticClass: "subheadline" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "components.playroomEditParts.InvoiceInformationPart.bankInfo"
                    )
                  )
                )
              ]),
              _c("ValidatedInput", {
                attrs: {
                  field: _vm.$v.playroom.invoiceIban,
                  value: _vm.$v.playroom.invoiceIban.$model,
                  translationKey: "playroom.invoiceIban"
                },
                on: {
                  "update:value": function($event) {
                    return _vm.$set(
                      _vm.$v.playroom.invoiceIban,
                      "$model",
                      $event
                    )
                  },
                  change: _vm.ibanChanged,
                  focusChange: _vm.onFocusChange
                }
              }),
              _c("ValidatedInput", {
                attrs: {
                  field: _vm.$v.playroom.invoiceBic,
                  value: _vm.$v.playroom.invoiceBic.$model,
                  translationKey: "playroom.invoiceBic"
                },
                on: {
                  "update:value": function($event) {
                    return _vm.$set(
                      _vm.$v.playroom.invoiceBic,
                      "$model",
                      $event
                    )
                  },
                  focusChange: _vm.onFocusChange
                }
              }),
              _c("ValidatedInput", {
                attrs: {
                  field: _vm.$v.playroom.invoiceBank,
                  value: _vm.$v.playroom.invoiceBank.$model,
                  translationKey: "playroom.invoiceBank"
                },
                on: {
                  "update:value": function($event) {
                    return _vm.$set(
                      _vm.$v.playroom.invoiceBank,
                      "$model",
                      $event
                    )
                  },
                  focusChange: _vm.onFocusChange
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCol",
        { attrs: { md: "4" } },
        [
          _c("HelpCard", {
            attrs: {
              focusFor: _vm.focusFor,
              name:
                "components.playroomEditParts.InvoiceInformationPart.info.box1"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }